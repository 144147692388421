import React from 'react';

import classes from '../../style.module.css'
import demons001 from '../../img/demons/demons001.jpg'
import demons002 from '../../img/demons/demons002.jpg'
import demons003 from '../../img/demons/demons003.jpg'

function Demons() {
    const style = {
        'marginTop' : '260px'
      };
    return <div >
        <article id={classes.info} style={style}>
            <h1>
                Night Parade of 100 Demons
            </h1>
            is based on the famous Japanese Lore “百鬼夜行". There are 100 Demons in series one. At the same time, we introduce the stories/ lores of these demons to demon/ lore lovers.
            <hr/>
            <hr/>
            <a href="https://www.muse.place/npdemons-gallery">Check our 3D Gallery here</a>
            <hr/>
            <div className={classes.center}>
                <img className={classes.pics} src={demons001}/>
                <img className={classes.pics} src={demons002}/>
                <img className={classes.pics} src={demons003}/>
            </div>
        </article>
    </div>;
}
  
export default Demons;