import React from 'react';

import classes from '../style.module.css';
import classes_Gallery from './Gallery.module.css'

import demons001 from '../img/demons/demons001.jpg'
import demons002 from '../img/demons/demons002.jpg'
import demons003 from '../img/demons/demons003.jpg'
import amulets001 from '../img/Amulets/amulets001.jpg'
import amulets002 from '../img/Amulets/amulets002.jpg'
import amulets003 from '../img/Amulets/amulets003.jpg'
import husky from '../img/Gallery-ExtraCollection/husky.jpg'
import Draft from '../img/Gallery-ExtraCollection/NPDemons002 Draft.jpg'
function GalleryPage() {
    return <div>
        <article>
            <h3>NPDemons collection</h3>

            <div className={classes_Gallery.gallerycontainer}>
                <div className={classes_Gallery.gallerybox}>
                    <img src={demons001} className={classes_Gallery.galleryimg}/>
                    <img src={demons002} className={classes_Gallery.galleryimg}/>
                    <img src={demons003} className={classes_Gallery.galleryimg}/>
                </div>
            </div>
        </article>

        <article>
            <h3>Auction Amulets</h3>
            <div className={classes_Gallery.gallerycontainer}>
                <div className={classes_Gallery.gallerybox}>
                    <img src={amulets001} className={classes_Gallery.galleryimg}/>
                    <img src={amulets002} className={classes_Gallery.galleryimg}/>
                    <img src={amulets003} className={classes_Gallery.galleryimg}/>
                </div>
            </div>
        </article>

        <article>
            <h3>NPDemons Extra Collection</h3>
            <div className={classes_Gallery.gallerycontainer}>
                <div className={classes_Gallery.gallerybox}>
                    <img src={husky} className={classes_Gallery.galleryimg}/>
                    <img src={Draft} className={classes_Gallery.galleryimg}/>
                </div>
            </div>
        </article>

    </div>;
}
  
export default GalleryPage;