import React from 'react';

import classes from '../../style.module.css';

function PolicyID_popout(props) {
    function cancelHandler() {
        props.onCancel();
    }
    
    return (
        <div className={classes.popout + ' ' + classes.center}>
        <hr/>
        <h2> PolicyID</h2>

        <hr/> PolicyID of Night Parade of 100 Demons:<br/> bec4f260ad9b8c4c2ee1d65dec4fff83b4210a008ffa4b61e4b91543

        <hr/> PolicyID of Auction Amulets:<br/> 3124bddc14e0a7f0871e013acbbe3969170006e00f0603385b6465c7

        <hr/> PolicyID of Cutémon:<br/> 29f2fdede501f7e7ee301c6c5db5162dae51d31cc63424177a107f0e
        <hr/>
        <button onClick={cancelHandler}>X</button>
    </div>
    );
}

export default PolicyID_popout;