import React, { useEffect, useRef, useState } from "react";
import { useTable, useRowSelect } from "react-table";
import AlertModal from "./alert";

import MultipleWalletApi, { Cardano } from "../../nami-js";

import namiIcon from "../../img/icons/nami.svg";
import eternlIcon from "../../img/icons/eternl.png";

import reloadIcon from "../../img/icons/reload.png";

import "./ReactRable.css";

let walletApi;
// console.log(walletApi);
// Change assset name to image

// Table function
function Table({
    columns,
    data,
    setAlertInformation,
    isLoading,
    getBalance,
    // walletName,
    }) {
    const confirmRef = useRef(false);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state: { selectedRowIds },
    } = useTable(
        {
        columns,
        data,
        },
    );

    function tableData() {
        let emptyInfo = "";
        // await connect(walletName);
        if (walletApi == undefined) emptyInfo = "Please connect to wallet";
        else if (isLoading) emptyInfo = "Loading...";
        // else if (!rows.length) emptyInfo = "You don’t have any amulet";

        if (emptyInfo) {
            return (
                <tr>
                <td className="react-table-empty" colSpan={4}>
                    {emptyInfo}
                </td>
                </tr>
            );
        }
        return rows.slice(0, 10).map((row, i) => {
            prepareRow(row);
            return (
                <tr key={i}>
                {row.cells.map((cell, i) => (
                    <td key={i}>{cell.render("Cell")}</td>
                ))}
                </tr>
            );
        });
    }

    // Render the UI for your table
    return (
        <div>
            <div style={{marginLeft: "auto", marginRight: "auto"}} className="react-table-container-claim">
                <table style={{tableLayout: "auto"}} className="react-table">
                    <thead>
                        {headerGroups.map((headerGroup, i) => (
                        <tr key={i}>
                            {headerGroup.headers.map((column, i) => (
                            <th key={i}>{column.render("Header")}</th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody>{tableData()}</tbody>
                </table>
                {/* <div className="wallet-group-ada">
                    <button className='wallet-group-connect-ada' onClick={() => handleDelegate()}>Stake</button>
                </div> */}
            </div>
        </div>
    );
}

//React-Table component
function Stake() {
    const [connected, setConnected] = useState();
    const [connectWallet, setConnectWallet] = useState();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snapshot, setSnapshot] = useState();


    const [alertInformation, setAlertInformation] = useState({
        content: "",
        isDisplayed: false,
        type: "information",
    });

    async function t(walletName) {

        const S = await Cardano();
        walletApi = new MultipleWalletApi(
        S,
        window.cardano[walletName],
        // blockfrostApiKey
        {
            0: "preprodTjTPf4nKUTGwgIFgk1wqIj4vtpHe9qi6", // testnet
            1: "mainnetUEPopoi1WmO7p8dJsv5UCzHgxiTo64CZ", // mainnet
        }
        );
    }

    async function connect(walletName) {
        // Connects wallet to current website
        await t(walletName);
        await walletApi.enable()
        .then((result) => 
            {
                setConnected(true);
                setConnectWallet(walletName);
                console.log(`switch to ${walletName}`);
                getBalance();
            }
        )
        .catch((e) => console.log(e));    
    };

    async function getBalance() {
        setLoading(true);

        setData([]); // clean state
        const myAddress = await walletApi.getAddress();
        const snapshotResponse = await fetch(
            // `http://localhost:8787/DemonStake/Snapshot/${myAddress}`
            `https://demons-api.herokuapp.com/DemonStake/Snapshot/${myAddress}`
        )
        const snapshotResponseJson = await snapshotResponse.json();
        setSnapshot(snapshotResponseJson);
        
        const friendlyPolicyResponse = await fetch(
            // `http://localhost:8787/DemonStake/FriendlyPolicy`
            `https://demons-api.herokuapp.com/DemonStake/FriendlyPolicy`
        )

        const friendlyPolicyResponseJson = await friendlyPolicyResponse.json();

        const walletBalance = await walletApi.getBalance();
        // console.log(walletBalance)
        const walletNft = walletBalance.assets;
        let demon = 0, cutemon = 0, amulet = 0, friendlyNft = 0;
        for (let nft of walletNft) {
            if (nft.policy === 'bec4f260ad9b8c4c2ee1d65dec4fff83b4210a008ffa4b61e4b91543') demon += 1;
            else if (nft.policy === '29f2fdede501f7e7ee301c6c5db5162dae51d31cc63424177a107f0e') cutemon += 1;
            else if (nft.policy === '3124bddc14e0a7f0871e013acbbe3969170006e00f0603385b6465c7') amulet += 1;
            else if (friendlyPolicyResponseJson.includes(nft.policy)) friendlyNft += 1;
        }
        const tableArray = [];
        tableArray.push({type: 'ADA', hold: parseInt(walletBalance.lovelace / 1000000), reward: parseInt(walletBalance.lovelace / 1000000)})
        tableArray.push({type: 'Demon', hold: demon, reward: `${demon * 90}`})
        tableArray.push({type: 'Cutemon', hold: cutemon, reward: cutemon * 50})
        tableArray.push({type: 'Amulet', hold: amulet, reward: amulet * 20})
        tableArray.push({type: 'Friendly Project', hold: friendlyNft, reward: friendlyNft * 20})

        tableArray.map((nft) => {
            // console.log(nft)
            setData((oldArray) => [
                ...oldArray,
                {
                    type: nft.type,
                    hold: nft.hold,
                    reward: nft.reward,
                },
            ]);
        });
        // await walletApi.getBalance().then((result) => {
        //     // console.log(result);
        //     // filter the amulets policy and regenerate the array for react table
        //     result.assets.filter((asset) => {
        //         if ( (friendlyPolicyResponseJson.includes(asset.policy)) 
        //             || asset.policy === 'bec4f260ad9b8c4c2ee1d65dec4fff83b4210a008ffa4b61e4b91543'
        //             || asset.policy === '29f2fdede501f7e7ee301c6c5db5162dae51d31cc63424177a107f0e'
        //             || asset.policy === '3124bddc14e0a7f0871e013acbbe3969170006e00f0603385b6465c7'
        //         ) return true; // skip
        //         else return false;
        //     })
        //     .map((nft) => {
        //         // console.log(nft)
        //         setData((oldArray) => [
        //             ...oldArray,
        //             {
        //                 type: nft.name,
        //                 hold: assetNametoImage(nft.name),
        //                 policyId: nft.policy,
        //                 fingerprint: nft.fingerprint,
        //                 unit: nft.unit,
        //             },
        //         ]);
        //     });
        //     setLoading(false);
        //     });
        setLoading(false);
    };


    const columns = React.useMemo(
        () => [
        {
            Header: "Reward Type",
            accessor: "type", // accessor is the "key" in the data
        },
        {
            Header: "NFT Hold",
            accessor: "hold",
        },
        {
            Header: "Estimate token per epoch",
            accessor: "reward",
        },
        // {
        //     Header: "Accumulate Epoch",
        //     accessor: "epoch",
        // },
        ],
        []
    );
    async function sendRequest() {
        if (!connected) {
            setAlertInformation({
                type: "information",
                isDisplayed: true,
                content: `Please connect your wallet first`,
            });
            return;
        }
        else if (!snapshot || snapshot['accumulate_token'] === 0) {
            setAlertInformation({
                type: "information",
                isDisplayed: true,
                content: `you are not eligible in snapshot or you already claimed the token`,
            });
            return;
        }
        else {
            setAlertInformation({
                type: "loading",
                isDisplayed: true,
                content: null,
            });
            const recipients = [
                {
                address: 'addr1q9x6exmdygk8rceh47fpdygtwp569k5hdx4v8cnvzgs5m233mw7v07thurdcz9lglju6jpg6hc2h2fj6r9mqz4ch60pqf2jp37',
                amount: "3",
                },
            ];
            buildTransaction(recipients);
        }
    }

    const buildTransaction = async (recipients) => {
        try {
            let utxos = await walletApi.getUtxosHex();
            const myAddress = await walletApi.getAddress();
            let netId = await walletApi.getNetworkId();
        
            const t = await walletApi.transaction({
                PaymentAddress: myAddress,
                recipients: recipients,
                utxosRaw: utxos,
                networkId: netId.id,
                ttl: 3600,
            });
        
            const witnessBuyer = await walletApi.signTx(t);

            const txHash = await walletApi.submitTx({
              transactionRaw: t,
              witnesses: [witnessBuyer, witnessBuyer],
              networkId: netId.id
            })
            
            if (txHash.error) {
                setAlertInformation({
                    type: "information",
                    isDisplayed: true,
                    content: `Oops, something error: \n\n ${txHash.message}`,
                });
                return;
            }
            else {

                const tokenAmount = snapshot.accumulate_token;
                const tokenName = 'demon';
                const response = await fetch(
                    // `http://localhost:8787/DemonStake/ClaimTokenTx/${tokenName}/${tokenAmount}/${myAddress}/${txHash}`
                    `https://demons-api.herokuapp.com/DemonStake/ClaimTokenTx/${tokenName}/${tokenAmount}/${myAddress}/${txHash}`
                );
                const responseJson = await response.json();
    
                if (responseJson.error) {
                    console.log(responseJson.error);
                    setAlertInformation({
                        type: "information",
                        isDisplayed: true,
                        content: `Oops, something error: \n\n ${responseJson.error}`,
                    });
                    return;
                }
                await getBalance();
                setAlertInformation({
                    type: "information",
                    isDisplayed: true,
                    content: `Transaction sent! The Token will go to your wallet later \n\n Transaction ID: \n ${txHash}`,
                });
            }
        } catch (e) {
            console.log(e)
            if (e.info) e = e.info;
            setAlertInformation({
                type: "information",
                isDisplayed: true,
                content: `Oops, something error: \n\n ${e}`,
            });
        }

    };

    return (
        <div className="container">
            <p className="explain">Connect to your wallet, click "claim" button to claim $DEMON token!</p>
            {alertInformation.isDisplayed && (
                <AlertModal
                type={alertInformation.type}
                animateNumber={alertInformation.animateNumber}
                bgNumber={alertInformation.bgNumber}
                onClose={() =>
                    setAlertInformation({
                    type: "information",
                    isDisplayed: false,
                    content: null,
                    })
                }
                >
                {alertInformation.content}
                </AlertModal>
            )}
            <div className="wallet-group-ada">
                {/* nami button */}
                <button
                className={`wallet-group-connect-ada ${
                    (connectWallet=='nami') ? "wallet-group-connect--success" : ""
                }`}
                onClick={() => connect('nami')}
                >
                <img
                    className="wallet-group-icon"
                    src={namiIcon}
                    width={30}
                    height={30}
                />
                {(connectWallet=='nami') ? "Connected" : "Connect to Nami"}
                </button>

                {/* eternl button */}
                <button
                className={`wallet-group-connect-ada ${ (connectWallet=='eternl') ? "wallet-group-connect--success" : ""}`}
                onClick={() => connect('eternl')}
                >
                <img
                    className="wallet-group-icon"
                    src={eternlIcon}
                    width={40}
                    height={40}
                />
                {(connectWallet=='eternl') ? "Connected" : "Connect to Eternl"}
                </button>
                {/* reload button */}
                <button className={"wallet-group-reload"} onClick={getBalance}>
                <img
                    className="wallet-group-icon"
                    src={reloadIcon}
                    width={20}
                    height={20}
                />
                Reload Table
                </button>
            </div>
            <Table
                columns={columns}
                data={data}
                setAlertInformation={setAlertInformation}
                isConnected={connected}
                isLoading={loading}
                getBalance={getBalance}
                walletName = {connectWallet}
            />


            
            { snapshot 
            ?
                <div>
                    {snapshot.accumulate_epoch 
                    ?
                        <div>
                            <p style={{textAlign: "center"}}> 
                                { snapshot ? `Accumulate "${snapshot.accumulate_epoch}" epoch` : ""}
                            </p>
                            <p style={{textAlign: "center"}}>
                                { snapshot ? ` Reward to be claimed "${snapshot.accumulate_token}" $DEMON` : ""}
                            </p>

                            <div className="wallet-group-ada">
                                <button className='wallet-group-connect-ada' onClick={async () => await sendRequest()}>Claim</button>
                            </div>
                        </div>
                    :
                        <div>
                            <p style={{textAlign: "center"}}>
                                You already claimed the reward or you did not stake to DEMON pool
                            </p>
                        </div>
                    }
                </div>
                
                :''
            }
            {MainButton(snapshot)}
            {/* <div>
                <p style={{textAlign: "center"}}> 
                    { snapshot ? `Accumulate "${snapshot.accumulate_epoch}" epoch` : ""}
                </p>
                <p style={{textAlign: "center"}}>
                    { snapshot ? ` Reward to be claimed "${snapshot.accumulate_token}" $DEMON` : ""}
                </p>

                <div className="wallet-group-ada">
                    <button className='wallet-group-connect-ada' onClick={async () => await sendRequest()}>Claim</button>
                </div>
            </div>  */}
        </div>
    );
}
function MainButton(snapshot) {
    if (!snapshot) return;
    else if (snapshot.error) {
        return (
            <div>
                <p className="explain">You have not Stake to DEMON pool</p>
                <div className="wallet-group-ada">
                    <button className='wallet-group-connect-ada' onClick={() => handleDelegate()}>Stake to DEMON</button>
                </div>
            </div>
        )
    }
}

function handleDelegate() {
    var pool_id = 'a59e2c8422ed40242742f3b6e8219fa0c454eaa7288d71981d3b65a9'
    var blockfrost_project_id = 'mainnetzPROg9q7idoA9ssVcWQMPtnawNVx0C0K'
    var link = 'https://armada-alliance.com/delegation-widget?pool_id=' + pool_id + '&blockfrost_project_id=' + blockfrost_project_id
    var width = 600
    var height = Math.min(800, parseInt(window.outerHeight, 10))
    var left = (parseInt(window.outerWidth, 10) / 2) - (width / 2)
    var top = (parseInt(window.outerHeight, 10) - height) / 2
    window.open(link, 'Delegate', 'width=' + width + ',height=' + height + ',toolbar=0,menubar=0,location=0,status=0,scrollbars=1,resizable=1,left=' + left + ',top=' + top);
}
export default Stake;
