import React from 'react';

import classes from '../style.module.css';
import classes_Club from './DemonClub.module.css'
import angry from '../img/DemonClubEmoji/angry.png';
import evil from '../img/DemonClubEmoji/Evil.png';
import happy from '../img/DemonClubEmoji/happy.png';
import love from '../img/DemonClubEmoji/love.png';
import sad from '../img/DemonClubEmoji/sad.png';
import surprise from '../img/DemonClubEmoji/surprise.png';

function DemonClubPage() {
    const style = {
        'color': 'green'
    }
    const style2 = {
        'color': 'brown'
    }

    return <div>
        <img src={angry} className={classes_Club.emoji + ' ' + classes_Club.angry}/>
        <img src={evil} className={classes_Club.emoji + ' ' + classes_Club.evil}/>
        <img src={happy} className={classes_Club.emoji + ' ' + classes_Club.happy}/>
        <img src={love} className={classes_Club.emoji + ' ' + classes_Club.love}/>
        <img src={sad} className={classes_Club.emoji + ' ' + classes_Club.sad}/>
        <img src={surprise} className={classes_Club.emoji + ' ' + classes_Club.surprise}/>

        <article className={classes.center}>
            <h1> What is Demon club? </h1>
            <hr/> Demon club is only for amulets, cutemons and demons holders.
            <hr/> Once you join demon club,<br/> you will get a <k style={style}>#DEMON ROLE</k> in our discord.
            <hr/> #amulets holders can join auctions for npdemons.
            <hr/> #Demon and cutemon holders can have some holders' privileges.
            <br/> If you have our NFTs, please go to our discord to be veridied holder<br/>
            {/* <a href="https://demons-web-app.herokuapp.com/" target="blank" style={style2}>Demon Club</a> to join demon club! */}
            <hr/>DEMON CLUB!<br/> The best community ever!
        </article>
    
    </div>;
}
  
export default DemonClubPage;
/*
<div class="popout center" id=idpopout>
        <hr>
        <h2> PolicyID</h2>

        <hr> PolicyID of Night Parade of 100 Demons:<br> bec4f260ad9b8c4c2ee1d65dec4fff83b4210a008ffa4b61e4b91543

        <hr> PolicyID of Auction Amulets:<br> 3124bddc14e0a7f0871e013acbbe3969170006e00f0603385b6465c7

        <hr> PolicyID of Cutémon:<br> Coming Soon...
        <hr>
        <close id=idpopoutclose>X</close>

    </div>
*/