// import React from 'react';

// import classes from '../style.module.css';
// import classes_Gallery from './Gallery.module.css'

// import wolf from '../img/partner/wolf.png'
// function Partner() {
//     return <div>
//         <article>
//             <h1 style={{textAlign: "center"}}>Stake Pool Partner List</h1>

//             <div className={classes_Gallery.gallerycontainer}>
//                 <div className={classes_Gallery.gallerybox}>
//                     <img src={wolf} className={classes_Gallery.galleryimg}/>
//                     <img src={wolf} className={classes_Gallery.galleryimg}/>
//                     <img src={wolf} className={classes_Gallery.galleryimg}/>
//                     <img src={wolf} className={classes_Gallery.galleryimg}/>
//                     <img src={wolf} className={classes_Gallery.galleryimg}/>
//                     <img src={wolf} className={classes_Gallery.galleryimg}/>
//                     <img src={wolf} className={classes_Gallery.galleryimg}/>
//                     <img src={wolf} className={classes_Gallery.galleryimg}/>
//                     <img src={wolf} className={classes_Gallery.galleryimg}/>
//                     <img src={wolf} className={classes_Gallery.galleryimg}/>

//                 </div>
//             </div>
//         </article>


//     </div>;
// }
  
// export default Partner;

import React, { useRef } from 'react'
import styled, { keyframes } from 'styled-components'

import wolf from '../img/partner/wolf.jpg'
// import img1 from '../../assets/Nfts/1.png';
// import img2 from '../../assets/Nfts/2.png';
// import img3 from '../../assets/Nfts/3.png';
// import img4 from '../../assets/Nfts/4.png';
// import img5 from '../../assets/Nfts/5.png';
// import img6 from '../../assets/Nfts/6.png';
// import img7 from '../../assets/Nfts/7.png';
// import img8 from '../../assets/Nfts/8.png';
// import img9 from '../../assets/Nfts/9.png';
// import img10 from '../../assets/Nfts/10.png';
// import ADA from '../../assets/ada.png'
// import backgroundImg from '../../assets/background.gif';



const Section = styled.section`
min-height: 100vh;
width: 100vw;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;
background-size: cover;

&>*:first-child{
  animation-duration: 20s;

  @media (max-width: 30em){
    animation-duration: 15s;

  }
}
&>*:last-child{
  animation-duration: 5s;
  @media (max-width: 30em){
    animation-duration: 5s;

  }
}
`
const move = keyframes`
0%{ transform: translateX(100%)   };
100%{ transform: translateX(-100%)   }

`

const Row = styled.div`
/* background-color: lightblue; */
white-space: nowrap;
box-sizing:content-box;
margin: 2rem 0;
display: flex;

animation: ${move}  linear infinite ${props => props.direction};


`
const ImgContainer = styled.div`
width: 15rem;
margin: 0 1rem;
background-color:${props => props.theme.body};

border-radius: 20px;
cursor: pointer;

@media (max-width: 48em){
  width: 12rem;
  }
  @media (max-width: 30em){
  width: 10rem;
  }

img{
  width: 100%;
  height: auto;
}
`

const Details = styled.div`
display: flex;
justify-content: space-between;
padding: 0.8rem 1rem;
background-color: black;
border: 2px solid ${props => `rgba(${props.theme.bodyRgba},0.5)`};

border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;

span{
  font-size: ${props => props.theme.fontsm};
  color: white;
  font-weight:600;
  line-height: 1.5rem;
}

h1{
  font-size: ${props => props.theme.fontmd};
  color: white;
  font-weight:600;

  @media (max-width: 30em){
    font-size: ${props => props.theme.fontsm};

  }

}

`

const Price = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;

img{
  width: 1rem;
  height: auto;

}
`

const NftItem = ({img, name='', price=0, passRef}) => {

    let play = (e) => {
    passRef.current.style.animationPlayState = 'running';
    }
    let pause = (e) => {
    passRef.current.style.animationPlayState = 'paused';
    }


    return(
        <ImgContainer   onMouseOver={e => pause(e) }  onMouseOut={e => play(e) }  >
        <img width={300} height={300}  src={img} alt="partner" />
        <Details>
            <div style={{textAlign: "center"}}>
            {/* <span>DDoS</span> <br /> */}
            <h1 >{name}</h1>
            </div>

            {/* <div>
                <span>Price</span>
                <Price>
                    <img width={400} height={400}  src={ADA} alt="ADA" />
                    <h1>{price}</h1>
                </Price>
            </div> */}
        </Details>
        </ImgContainer>
    )
} 


const Showcase = () => {

  const Row1Ref = useRef(null);
  const Row2Ref = useRef(null);

  return(
    <Section id="showcase">
    <Row direction="none" ref={Row1Ref}>
      <NftItem img={wolf}  name={'Cardano Wolf'}  passRef = {Row1Ref} />
      <NftItem img={wolf}  name={'World of Cardano'}  passRef = {Row1Ref} />
      <NftItem img={wolf}  name={'World'}  passRef = {Row1Ref} />



      {/* <NftItem img={img2}  number={'???'} price={'?'}   passRef = {Row1Ref} />
      <NftItem img={img3}  number={'???'} price={'?'}   passRef = {Row1Ref} />
      <NftItem img={img4}  number={'???'} price={'?'}   passRef = {Row1Ref} />
      <NftItem img={img5}  number={'???'} price={'?'}   passRef = {Row1Ref} />
 */}

    </Row>
    {/* <Row direction="reverse" ref={Row2Ref}>
    </Row> */}
    </Section>
  )
}

export default Showcase