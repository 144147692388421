import React from "react";

import Demons from "../components/articles/Demons";
import Collection from "../components/articles/Collection";
import Amulets from "../components/articles/Amulets";
import Cutemon from "../components/articles/Cutemon";
import PolicyID from "../components/articles/PolicyID";

function MainPage() {
  return (
    <div>
      <Demons />
      <Collection />
      <Amulets />
      <Cutemon />
      <PolicyID />
    </div>
  );
}

export default MainPage;
