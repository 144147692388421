import React from 'react';

import classes from '../../style.module.css'

function Amulets() {
    return <div >
        <article id={classes.amulets}>
            <h1>
                Auction Amulets
            </h1>
            Amulets, according to Japanese traditional cultures, can bring people good luck and protection. With one Amulet. There are also 100 kinds of Amulets. Every amulet corresponds to a specific demon. BUT you can take part in every auction if you have ONE
            amulet. They are 60 pieces of each kind. we make sure you won't be attacked by demons during the auctions!
        </article>
    </div>;
}
  
export default Amulets;