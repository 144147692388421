import React from 'react';

import classes from '../../style.module.css';
import cutemon from '../../img/capsule/cutemon.gif'
import amulets from '../../img/roadmap/amulets.png'

function Roadmap() {
    const style2 = {
        'width' : '800px',
        'height' : '60px',
        'display' : 'block'
    };
    
    return <div>
        <article id={classes.roadmap} >
            <h1>Roadmap</h1>
            <h2>——We hold auctions for NPDemons and mint AuctionAmulets every week</h2>
            <div style={style2} className={classes.roadmaptext}></div>
            <div className={classes.roadmap}>
                <img src={amulets} className={classes.roadmapimg}/>
                <div className={classes.roadmaptext}>
                    <h2>2022 Q1</h2>
                    - Capsule machines of Cutémons(Jan. 30)<br/>
                    - Cutémon official Launch(Feb. 6)<br/> 
                    - Write an auction bot in our discord<br/> 
                    - Recruit new developers, artists and community managers<br/> 
                    - Connect Nami and Discord on our website<br/> 
                    - Create Partnerships with other projects(Community poll)
                </div>
            </div>
            <div className={classes.roadmap}>
                <img src={cutemon} className={classes.roadmapimg}/>
                <div className={classes.roadmaptext}>
                    <h2>2022 Q2</h2>
                    - Capsule machines of 3D Cutémon Pets<br/> 
                    - 3D Cutémon Pets Launch(Cutémon holders guaranteed)<br/>
                    - 3D Cutémons and NPDemons Design for metaverse integration<br/>
                    - Integrate our NFTs into other metaverse projects on Cardano <br/>
                    &nbsp; &nbsp; (3D Gallery, Auction space, battle arena, and so on)
                </div>
            </div>
            <h2>2022 After Q1 and Q2</h2>
            We are not going to build “Demonverse” ourselves(We won’t mint plots like pavia or ada realm). Instead, we will manage to teamwork with them and integrate our NFTs into these metaverse projects.<br/>
            <br/><br/>
            But this doesn’t mean we won’t build a game. From 2022 Q1, we will recruit some game developers and start to design some game systems and a complete worldview for all collections. <br/>
            <br/><br/>
            Here is a schedule list:<br/>
            &nbsp; &nbsp;- P2E Game, earn our tokens by battles.<br/>
            &nbsp; &nbsp;- Demon Stake Pool, stake our NFT collections to earn tokens<br/>
            &nbsp; &nbsp;- List our tokens on DEXs of Cardano<br/>
            &nbsp; &nbsp; &nbsp; &nbsp;(We expect some DEXs coming out in 2022 Q2)<br/>
            <br/><br/>
            We are not sure how long it will take for us to complete this schedule list. Creating a game takes a lot of time. It’s part of the reasons why we want to cooperate with other metaverse projects(much more efficient).<br/>
            <br/><br/>
            We also expect to become a DAO after these are done. This is why we will start our community poll since 2022 Q1.
        </article>
    </div>;
}
  
export default Roadmap;

