import React, { useEffect, useRef, useState } from "react";
import AlertModal from "./alert";

import MultipleWalletApi, { Cardano } from "../../nami-js";
import { useTable } from 'react-table';
import namiIcon from "../../img/icons/nami.svg";
import eternlIcon from "../../img/icons/eternl.png";

import Select from 'react-select'

import "./ReactRable.css";

let walletApi;
// const buyingAmount = 1;
const amuletName = 'Bell,Rope';

function AddressHistory({columns, data,}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  return (
    <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                style={{
                  borderBottom: 'solid 3px red',
                  background: 'aliceblue',
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: '10px',
                      border: 'solid 1px gray',
                      background: 'papayawhip',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

// Table function
function MintProcess ({
  setAlertInformation,
  isConnected,
  nftAmount,
  buyingAmount,
  walletName,
}) {
  const confirmRef = useRef(false);

  //---------------------------------------------------------------------------------------------------------------------------------------------------
  // Send Selected Assets
  const sendNft = async () => {

    // 確認視窗
    if (!confirmRef.current) {
      setAlertInformation({
        type: "information",
        isDisplayed: true,
        content: (
          <div className="alert-slot">
            Insert {buyingAmount*15} ada for amulet?
            <div>
              <button
                className="alert-slot--cancel"
                onClick={() => {
                  confirmRef.current = false;
                  setAlertInformation({
                    type: "information",
                    isDisplayed: false,
                    content: null,
                  });
                }}
              >
                Cancel
              </button>
              <button
                className="alert-slot--confirm"
                onClick={() => {
                  setAlertInformation({
                    type: "information",
                    isDisplayed: false,
                    content: null,
                  });
                  confirmRef.current = true;
                  sendNft();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        ),
      });
      return;
    }

    // loading 視窗
    setAlertInformation({
      type: "loading",
      isDisplayed: true,
      content: null,
    });

    let preData, myAddress;

    try {
      myAddress = await walletApi.getAddress();
      preData = await fetch(
        // `http://localhost:8787/Amulet/RandomGet/${buyingAmount}/${amuletName}`
        `https://demons-api.herokuapp.com/Amulet/RandomGet/${buyingAmount}/${amuletName}`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.error) {
            setAlertInformation({
              type: "information",
              isDisplayed: true,
              content: `${data.error}`,
            });
            return;
          }
          else {
            return data;
          }
        });
    } catch(err) {
      console.log(err);
      setAlertInformation({
        type: "information",
        isDisplayed: true,
        content: "Oops, something error",
      });
      return;
    }
    let mintedAssetsArray = [];
    console.log()
    for (let i = 0; i < preData.nftName.length; i++) {
      mintedAssetsArray.push({
        assetName: preData.nftName[i], // from api 2.抽取扭蛋機
        quantity: "1",
        policyId:
          "3124bddc14e0a7f0871e013acbbe3969170006e00f0603385b6465c7",
        policyScript:
          "8201818200581c94b75de16190f7b2fe9f12580f9aa732c911246db5fac8530fca00de",
      });
    }
    const recipients = [
      {
        address: myAddress,
        amount: "0",
        mintedAssets: mintedAssetsArray
      },
      {
        // receive address
        address:
          "addr1qxyc2q4fvklq5nglw8gz0kf5l75gwnlkjvzmja6lu92kg8nes5lm6reejzwsv0xl26jg8spqlu5fcm9shu3nnam8ml5stfg2t5",
          // "addr_test1qznqcjkwvqh978hdr7ua4xwau30fw5plz5dcxe92glfm5ulf90wpk7vqwg0v8m3rnfq2q34a7pk7005e79vmf9pwcd7qeaj2de",
        amount: 15 * buyingAmount,
      },
    ];

    buildTransaction(recipients, preData);
    confirmRef.current = false;
  };
  function makeDummyMetadata(preData) { // TODO: check again
    let dummyMetadata = 
    {
        "721":{
            "3124bddc14e0a7f0871e013acbbe3969170006e00f0603385b6465c7":{
            }
        }
    };
    const dummyAsset = {
          "name": "AuctionAmulets #1080",
          "image": "ipfs://QmSWiM6cyutvNXhsfJHXhCWyUZtTMoS7HHMncwdh2YCyX3",
          "mediaType": "image/gif",
          "Amulet": "The Bell 鐘",
          "Demon": "Kiyohime 清姬",
          "Project": "Night Parade of 100 Demons",
          "NPDemons Links": {
            "Website": "https://www.npdemons.com/",
            "Twitter": "https://twitter.com/NP_DemonS",
            "Discord": "https://discord.com/invite/t2qDgDFAx7"
          }
    };
    // increase utxo + 1
    for (let i = 0; i < preData.nftName.length+1; i++) {
        // const nftName = preData.nftName[i];
        dummyMetadata['721']['3124bddc14e0a7f0871e013acbbe3969170006e00f0603385b6465c7'][i] = dummyAsset;
    }
    return dummyMetadata;
}
  // Build Transaction (send amulet and nft maker api)
  const buildTransaction = async (recipients, preData) => {
    // const dummyMetadata = {"721":{"3124bddc14e0a7f0871e013acbbe3969170006e00f0603385b6465c7":{"AuctionAmulets0425":{"name":"AuctionAmulets #0425","image":"ipfs://QmctA14DG3JFbvHm4kQciP4aGxa6mfiuehf1qTCtpFsigi","mediaType":"image/gif","Amulet":"Hammer 錘子","Demon":"Oboroguruma 朧車","Project":"Night Parade of 100 Demons","NPDemons Links":{"Website":"https://www.npdemons.com/","Twitter":"https://twitter.com/NP_DemonS","Discord":"https://discord.com/invite/t2qDgDFAx7"}},"AuctionAmulets0476":{"name":"AuctionAmulets #0476","image":"ipfs://QmctA14DG3JFbvHm4kQciP4aGxa6mfiuehf1qTCtpFsigi","mediaType":"image/gif","Amulet":"Hammer 錘子","Demon":"Oboroguruma 朧車","Project":"Night Parade of 100 Demons","NPDemons Links":{"Website":"https://www.npdemons.com/","Twitter":"https://twitter.com/NP_DemonS","Discord":"https://discord.com/invite/t2qDgDFAx7"}},"AuctionAmulets0471":{"name":"AuctionAmulets #0471","image":"ipfs://QmctA14DG3JFbvHm4kQciP4aGxa6mfiuehf1qTCtpFsigi","mediaType":"image/gif","Amulet":"Hammer 錘子","Demon":"Oboroguruma 朧車","Project":"Night Parade of 100 Demons","NPDemons Links":{"Website":"https://www.npdemons.com/","Twitter":"https://twitter.com/NP_DemonS","Discord":"https://discord.com/invite/t2qDgDFAx7"}}}}};
    const dummyMetadata = makeDummyMetadata(preData);
    // if (!isConnected) {
    //   await connect(walletName);
    //   // console.log('wallet not connect');
    //   return;
    // }
    try {
      let utxos = await walletApi.getUtxosHex();
      const myAddress = await walletApi.getAddress();
      let netId = await walletApi.getNetworkId();

      const t = await walletApi.transaction({
        PaymentAddress: myAddress,
        recipients: recipients,
        metadataHash: preData.metaDataHash,
        metadata: dummyMetadata,
        utxosRaw: utxos,
        networkId: netId.id,
        ttl: 3600,
        addMetadata: false,
        multiSig: true,
      });

      const witnessBuyer = await walletApi.signTx(t, true);

      // combine nft name (a,b,c)
      let nftNames = '';
      for (let i = 0; i < preData.nftName.length; i++){
          if (i === 0) {
              nftNames = nftNames + preData.nftName[i];
          }
          else {
              nftNames = nftNames + ',' + preData.nftName[i];
          }
      }
      fetch(
        `https://demons-api.herokuapp.com/Amulet/MultiSig/${t}/${witnessBuyer}/${nftNames}/${myAddress}/${amuletName}/${buyingAmount}`
        // `http://localhost:8787/Amulet/MultiSig/${t}/${witnessBuyer}/${nftNames}/${myAddress}/${amuletName}/${buyingAmount}`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
            console.log(data);
          if (data.error) {
            setAlertInformation({
              type: "information",
              isDisplayed: true,
              content: `${data.error}`,
            });
            return;
          }

          setAlertInformation({
            type: "animate",
            isDisplayed: true,
            content: null,
            animateNumber: 0, // 2 4 6
          });
          setTimeout(() => {
            setAlertInformation({
              type: "result",
              isDisplayed: true,
              content: (
                <>
                  <p>You Got {nftNames}!!!</p>
                  <img
                    width="200px"
                    src={data.imgPath[0]}
                    alt={data.txHash}
                    style={{ margin: "1.5rem 13rem" }}
                  />
                  <p>Transaction ID: {data.txHash}
                  <br/>Because of Cardano congestion, NFT takes time to go to your wallet </p>
                </>
              ),
              bgNumber: 1, // 0:capsule 1:capsule
            });
          }, 6500);
        });
    } catch (e) {
        console.log(e)
      setAlertInformation({
        type: "information",
        isDisplayed: true,
        content: "Oops, something error",
      });
    }
  };
  // ------------------------------------------------------------------------------------------------------------------------------------

  // Render the UI for your table
  return (
    <div className="capsule-container-ada">
      <div>
        <button
          className={`slot-button slot-button-amulet`}
          onClick={() => {
            sendNft();
          }}
        />
        <p className="slot-amount">total supply:{nftAmount[0]+nftAmount[1]+nftAmount[2]}    free: {nftAmount[0]}    reserved:{nftAmount[1]}    mint:{nftAmount[2]}</p>
      </div>
    </div>
  );
}


//Mint component
function Mint() {
  const [connected, setConnected] = useState();
  // default is nami wallet
  const [connectWallet, setConnectWallet] = useState();
  const [alertInformation, setAlertInformation] = useState({
    content: "",
    isDisplayed: false,
    type: "information",
  });
  const [nftAmount, setNftAmount] = useState([0, 0, 0]); // [free, reserve, mint]
  const [columnData, setColumnData] = useState([]);

  async function t(walletName) {

    const S = await Cardano();
    walletApi = new MultipleWalletApi(
      S,
      window.cardano[walletName],
      // blockfrostApiKey
      {
        0: "preprodTjTPf4nKUTGwgIFgk1wqIj4vtpHe9qi6", // testnet
        1: "mainnetUEPopoi1WmO7p8dJsv5UCzHgxiTo64CZ", // mainnet
      }
    );
  }

  const connect = async (walletName) => {
    // Connects wallet to current website
    await t(walletName);
    await walletApi.enable()
      .then((result) => 
        {
          setConnected(result);
          setConnectWallet(walletName);
          console.log(`switch to ${walletName}`)
        }
      )
      .catch((e) => console.log(e));    
  };

  const getNftAmount = async () => {
    const response =  await fetch(
      // `http://localhost:8787/Amulet/status/${amuletName}`
      `https://demons-api.herokuapp.com/Amulet/status/${amuletName}`
    );
    const data = await response.json();
    setNftAmount([data.free, data.reserve, data.mint]);
    // return data;
  };
  const getAddressHistory = async () => {
    const myAddress = await walletApi.getAddress();
    const response =  await fetch(
      // `http://localhost:8787/Amulet/status/${myAddress}/${amuletName}`
      `https://demons-api.herokuapp.com/Amulet/status/${myAddress}/${amuletName}`
    );
    const data = await response.json();
    setColumnData(data.addressData);
  };

  useEffect(() => {
    getNftAmount();
  }, []);

  useEffect(() => {
    if (connected) {
      getAddressHistory();
    }
  }, [connected]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Asset",
        accessor: "nftname", // accessor is the "key" in the data
      },
      {
        Header: "NFT",
        accessor: "imgpath",
        Cell: ({ cell: { value } }) => (
          <div>
            <img width="56" src={value} alt={value} />
          </div>
        ),
      },
      {
        Header: "Tx Hash",
        accessor: "txhash", // accessor is the "key" in the data
      },
      {
        Header: "Status",
        accessor: "status", // accessor is the "key" in the data
      },
      {
        Header: "Buy Time",
        accessor: "time", // accessor is the "key" in the data
      },
      
    ],
    []
  )

  // drop down select
  const [selectedOption, setSelectedOption] = useState({ value: 1, label: '1' });
  const options = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' }
  ]
      

  return (
    <div className="container">
      
      <p className="explain">Connect your wallet, Mint Amulet for {selectedOption.value * 15} ada.</p>
      <Select
      defaultValue={selectedOption}
      onChange={setSelectedOption}
      options={options}
      />
            
      {alertInformation.isDisplayed && (
        <AlertModal
          type={alertInformation.type}
          animateNumber={alertInformation.animateNumber}
          bgNumber={alertInformation.bgNumber}
          onClose={() =>
            setAlertInformation({
              type: "information",
              isDisplayed: false,
              content: null,
            })
          }
        >
          {alertInformation.content}
        </AlertModal>
      )}
      <div className="wallet-group-ada">

        {/* nami button */}
        <button
          className={`wallet-group-connect-ada ${
            (connectWallet=='nami') ? "wallet-group-connect--success" : ""
          }`}
          onClick={() => connect('nami')}
        >
          <img
            className="wallet-group-icon"
            src={namiIcon}
            width={30}
            height={30}
          />
          {(connectWallet=='nami') ? "Connected" : "Connect to Nami"}
        </button>

        {/* eternl button */}
        <button
          className={`wallet-group-connect-ada ${ (connectWallet=='eternl') ? "wallet-group-connect--success" : ""}`}
          onClick={() => connect('eternl')}
        >
          <img
            className="wallet-group-icon"
            src={eternlIcon}
            width={40}
            height={40}
          />
          {(connectWallet=='eternl') ? "Connected" : "Connect to Eternl"}
        </button>
      </div>

      <MintProcess
        setAlertInformation={setAlertInformation}
        isConnected={connected}
        nftAmount={nftAmount}
        buyingAmount={selectedOption.value}
        walletName = {connectWallet}
      />

      <AddressHistory
        columns={columns}
        data={columnData}
      />
      {/* <p>{JSON.stringify(columnData)}</p> */}
    </div>
  );
}

export default Mint;
