import React from 'react';

import classes from '../../style.module.css'
import cutemon from '../../img/capsule/cutemon.gif'
import amulets from '../../img/roadmap/amulets.png'


function Cutemon() {
    return <div >
        <article id={classes.cutemon}>
            <h1>
                Cutémon
            </h1>
            On January 30th, we have a multiple NFT drop called Cutémon! There are in total 3,5xx Cutémons. It's about a Japanese festival, Setsubun(せつぶん 節分). In this festival, people spray beans to protect themselves and drive demons away.
            <br/>
            <hr/>However, now the situation is totally different. Cutémons are so cute that people can't RESIST to play with them!!
            <hr/>
            <hr/>
            <div className={classes.center}>
                <img className={classes.pics} src={cutemon}/>
                <img className={classes.pics} src={amulets}/>
            </div>
        </article>
    </div>;
}
  
export default Cutemon;