import { Link } from "react-router-dom";
import React, { useState } from "react";
//import { HashLink } from 'react-router-hash-link';

import logo from "../../img/logo.png";

import "./MainNavigation.css";

function MainNavigation() {
  return (
    <header className="header--pc">
      <Link to="/">
        <img className="header-logo" src={logo} />
      </Link>

      <nav>
        <button>
          <Link to="schedule">Schedule</Link>
        </button>
        <button>
          <Link to="/roadmap">Roadmap</Link>
        </button>
        <button>
          <Link to="/team">Team</Link>
        </button>
        {/* <button>
          <Link to="/partner">Partner</Link>
        </button> */}
        {/* <button>
          <Link to="/gallery">Gallery</Link>
        </button> */}
        {/* <button>
          <Link to="/capsuleMachine">Capsule Machine</Link>
        </button> */}
        {/* <button>
          <Link to="/demonClub">Demon Club</Link>
        </button> */}
        <button>
          <Link to="/mint">Mint Amulet</Link>
        </button>
        <button>
          <Link to="/rugpull">Rugpull Helping Center</Link>
        </button>
        <button>
          <Link to="/stake">Staking</Link>
        </button>
        {/* <button><Link to=''>Connect Wallet</Link></button> */}
      </nav>
    </header>
  );
}

export default MainNavigation;
