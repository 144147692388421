import { Routes, Route } from "react-router-dom";
import React from "react";

import classes from "./style.module.css";

import MainPage from "./pages/MainPage";
import GalleryPage from "./pages/Gallery";
import DemonClubPage from "./pages/DemonClub";
import CapsuleMachinePage from "./pages/CapsuleMachine";
import Schedule from "./components/articles/Schedule";
import Roadmap from "./components/articles/Roadmap";
import Team from "./components/articles/Team";
// import MintCutemonPage from "./pages/MintCutemon";
import Mint from "./components/ui/mint";
import Stake from "./components/ui/Stake";


import SwapTable from "./components/ui/SwapTable";
import MintAny from "./components/ui/MintAny";
import Rugpull from "./components/ui/Rugpull";
import SendMany from "./components/ui/SendMany";
import Partner from "./pages/Partner";




//import Layout from './components/layout/Layout';
import MainNavigation from "./components/layout/MainNavigation";
import MainNavigation_mobile from "./components/layout/MainNavigation_mobile";
import Menu from "./components/ui/menu";

import useWindowSize from "./hook/useWindowSize";

function App() {
  const size = useWindowSize();

  return (
    <>
      {size.width > 800 ? <MainNavigation /> : <MainNavigation_mobile />}
      <div className={classes["root-container"]}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/team" element={<Team />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/demonClub" element={<DemonClubPage />} />
          <Route path="/capsuleMachine" element={<CapsuleMachinePage />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/capsule" element={<SwapTable />} />
          <Route path="/mintany" element={<MintAny />} />
          <Route path="/rugpull" element={<Rugpull />} />
          <Route path="/sendmany" element={<SendMany />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/stake" element={<Stake />} />


        </Routes>

        {size.width > 800 && <Menu />}
      </div>
    </>
  );
}

export default App;
