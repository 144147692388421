import React, { useEffect, useRef, useState } from "react";

import MultipleWalletApi, { Cardano } from "../../nami-js";
import namiIcon from "../../img/icons/nami.svg";
import eternlIcon from "../../img/icons/eternl.png";


import "./ReactRable.css";

let walletApi;


//Mint component
function MintAny() {
  const [connected, setConnected] = useState();
  // default is nami wallet
  const [connectWallet, setConnectWallet] = useState();
  const [skey, setSkey] = useState('input-skey');
  const [recipients, setRecipients] = useState(`{
        "address": "receive-address",
        "amount": "0",
        "mintedAssets": [{
            "assetName": "NFT-name",
            "quantity": "1",
            "policyId": "29f2fdede501f7e7ee301c6c5db5162dae51d31cc63424177a107f0e",
            "policyScript": "8201828200581c48c1d00b9c2adfffda0e6c28bd3e05dd1f1ac30aec4f94ad535176e382051a089725aa"
        }]
    }`);
  const [metadata, setMetadata] = useState(`input-metadata`);


  async function t(walletName) {

    const S = await Cardano();
    walletApi = new MultipleWalletApi(
      S,
      window.cardano[walletName],
      // blockfrostApiKey
      {
        0: "preprodTjTPf4nKUTGwgIFgk1wqIj4vtpHe9qi6", // testnet
        1: "mainnetUEPopoi1WmO7p8dJsv5UCzHgxiTo64CZ", // mainnet
      }
    );
  }

  const connect = async (walletName) => {
    // Connects wallet to current website
    await t(walletName);
    await walletApi.enable()
      .then((result) => 
        {
          setConnected(result);
          setConnectWallet(walletName);
          console.log(`switch to ${walletName}`)
        }
      )
      .catch((e) => console.log(e));    
  };

  async function mint() {
    // const dummyMetadata = {"721":{"3124bddc14e0a7f0871e013acbbe3969170006e00f0603385b6465c7":{"AuctionAmulets0425":{"name":"AuctionAmulets #0425","image":"ipfs://QmctA14DG3JFbvHm4kQciP4aGxa6mfiuehf1qTCtpFsigi","mediaType":"image/gif","Amulet":"Hammer 錘子","Demon":"Oboroguruma 朧車","Project":"Night Parade of 100 Demons","NPDemons Links":{"Website":"https://www.npdemons.com/","Twitter":"https://twitter.com/NP_DemonS","Discord":"https://discord.com/invite/t2qDgDFAx7"}},"AuctionAmulets0476":{"name":"AuctionAmulets #0476","image":"ipfs://QmctA14DG3JFbvHm4kQciP4aGxa6mfiuehf1qTCtpFsigi","mediaType":"image/gif","Amulet":"Hammer 錘子","Demon":"Oboroguruma 朧車","Project":"Night Parade of 100 Demons","NPDemons Links":{"Website":"https://www.npdemons.com/","Twitter":"https://twitter.com/NP_DemonS","Discord":"https://discord.com/invite/t2qDgDFAx7"}},"AuctionAmulets0471":{"name":"AuctionAmulets #0471","image":"ipfs://QmctA14DG3JFbvHm4kQciP4aGxa6mfiuehf1qTCtpFsigi","mediaType":"image/gif","Amulet":"Hammer 錘子","Demon":"Oboroguruma 朧車","Project":"Night Parade of 100 Demons","NPDemons Links":{"Website":"https://www.npdemons.com/","Twitter":"https://twitter.com/NP_DemonS","Discord":"https://discord.com/invite/t2qDgDFAx7"}}}}};

    const recipientsArray = [JSON.parse(recipients)]
    console.log(recipientsArray)
    try {
        let utxos = await walletApi.getUtxosHex();
        console.log(utxos)

        const myAddress = await walletApi.getAddress();
        console.log(myAddress)
        let netId = await walletApi.getNetworkId();
  
        console.log(recipientsArray);
        console.log(JSON.parse(metadata));
        const t = await walletApi.transaction({
          PaymentAddress: myAddress,
          recipients: recipientsArray,
          metadata: JSON.parse(metadata),
          utxosRaw: utxos,
          networkId: netId.id,
          ttl: 3600,
          multiSig: true,
        });
  
        const witnessBuyer = await walletApi.signTx(t, true);

        // fetch(
        //   `https://demons-api.herokuapp.com/MintAny/${netId.id}/${t}/${witnessBuyer}/${skey}`
        //   // `http://localhost:8787/MintAny/${netId.id}/${t}/${witnessBuyer}/${skey}`
        // )
        // .then((response) => {
        //   return response.json();
        // })
        // .then((data) => {
        //     console.log(data);
        // })
        const body = {
          buyerTx: t,
          witnessBuyer: witnessBuyer,
          skey: skey,
          nerkworkId: netId.id,
        }
        const response = await (await fetch(
            `https://demons-api.herokuapp.com/MintAny`,
            // `http://localhost:8787/MintAny/`,
            {
              method:'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(body)
            }
        )).json();

        console.log(response)
    }
    catch(e) {
        console.log(e);
    }
  }

      

  return (
    <div className="container">
      
      <div className="wallet-group-ada">

        {/* nami button */}
        <button
          className={`wallet-group-connect-ada ${
            (connectWallet=='nami') ? "wallet-group-connect--success" : ""
          }`}
          onClick={() => connect('nami')}
        >
          <img
            className="wallet-group-icon"
            src={namiIcon}
            width={30}
            height={30}
          />
          {(connectWallet=='nami') ? "Connected" : "Connect to Nami"}
        </button>

        {/* eternl button */}
        <button
          className={`wallet-group-connect-ada ${ (connectWallet=='eternl') ? "wallet-group-connect--success" : ""}`}
          onClick={() => connect('eternl')}
        >
          <img
            className="wallet-group-icon"
            src={eternlIcon}
            width={40}
            height={40}
          />
          {(connectWallet=='eternl') ? "Connected" : "Connect to Eternl"}
        </button>
      </div>
        <textarea style={{ width: "1000px", height: "50px", }}
            value={skey}
            onChange={(event) => {
                setSkey((prevState) => (event.target.value))
            }} 
        />
        <textarea style={{ width: "1000px", height: "350px", }}
            value={recipients}
            onChange={(event) => {
                setRecipients((prevState) => (event.target.value))
            }} 
        />
        <textarea style={{ width: "1000px", height: "500px", }}
            value={metadata}
            onChange={(event) => {
                setMetadata((prevState) => (event.target.value))
            }} 
        />

        <button className={`wallet-group-connect-ada`}
          onClick={() => mint()}>
          test
        </button>
    </div>
  );
}

export default MintAny;
