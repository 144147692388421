// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CE6hqh5qnjvnhCUCR7rw {\n    /* background-color: cornflowerblue; */\n    width: 86vw;\n    left: 0%;\n    margin-bottom: 10px;\n    min-height: 400px;\n}\n\n.t0Tp2RUws7x5BfJ7OjM1 {\n    height: 300px;\n    float: left;\n    margin-right: 20px;\n    margin-bottom: 20px;\n}\n\n.Tx7un_g3InGNU9_PcrFb:hover,\n.tX089l49PLy6QlT33VOQ:hover {\n    text-shadow: -3px 3px 2px rgba(0, 0, 0, 0.5);\n}\n\n.tX089l49PLy6QlT33VOQ {\n    z-index: 99;\n    font-size: 100px;\n    position: absolute;\n    top: 540px;\n    right: 100px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Gallery.module.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,WAAW;IACX,QAAQ;IACR,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;;IAEI,4CAA4C;AAChD;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,YAAY;AAChB","sourcesContent":[".gallerycontainer {\n    /* background-color: cornflowerblue; */\n    width: 86vw;\n    left: 0%;\n    margin-bottom: 10px;\n    min-height: 400px;\n}\n\n.galleryimg {\n    height: 300px;\n    float: left;\n    margin-right: 20px;\n    margin-bottom: 20px;\n}\n\n.leftbtn:hover,\n.rightbtn:hover {\n    text-shadow: -3px 3px 2px rgba(0, 0, 0, 0.5);\n}\n\n.rightbtn {\n    z-index: 99;\n    font-size: 100px;\n    position: absolute;\n    top: 540px;\n    right: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gallerycontainer": "CE6hqh5qnjvnhCUCR7rw",
	"galleryimg": "t0Tp2RUws7x5BfJ7OjM1",
	"leftbtn": "Tx7un_g3InGNU9_PcrFb",
	"rightbtn": "tX089l49PLy6QlT33VOQ"
};
export default ___CSS_LOADER_EXPORT___;
