import React, { useEffect, useRef, useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import AlertModal from "./alert";

import MultipleWalletApi, { Cardano } from "../../nami-js";


import namiIcon from "../../img/icons/nami.svg";
import eternlIcon from "../../img/icons/eternl.png";

import reloadIcon from "../../img/icons/reload.png";
import rugpullThumbnail from "../../img/capsule/machine/rugpull-thumbnail.jpeg";

import { getCookie, hasher, setCookie } from '../../hook/cookie';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';



import "./ReactRable.css";

const sessionCookieBump = () => {
    const cookieValue = getCookie('sessionID');
    if (cookieValue.length === 0) {
        const uuid = uuidv4();
        setCookie('sessionID', uuid, 1)
    }
    else {
        setCookie('sessionID', cookieValue, 1)
    }
}

const AppState = {
    NeedLogIn: 1,
    LoggedIn: 2,
};
Object.freeze(AppState);

let walletApi;
// Checkbox
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);



// Table function
function Table({
    columns,
    data,
    setAlertInformation,
    isLoading,
    getBalance,
    discordId,
    // walletName,
    }) {
    const confirmRef = useRef(false);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows ->
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,

        prepareRow,
        selectedFlatRows,
        state: {  pageIndex, pageSize, selectedRowIds },
    } = useTable(
        {
        columns,
        data,
        },
        usePagination,
        useRowSelect,
        (hooks) => {
        hooks.visibleColumns.push((columns) => [
            // Let's make a column for selection
            {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
                <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
            ),
            },
            ...columns,
        ]);
        }
    );
    // Send Selected Assets
    const sendSelected = async () => {
        const qty = Object.keys(selectedRowIds).length;

        // 選擇不夠
        if (qty <= 0) {
            setAlertInformation({
                type: "information",
                isDisplayed: true,
                content: `RugPull Helping Center requires at least one rugpull NFT \n but you select ${
                Object.keys(selectedRowIds).length
                } rugpull NFT(s)`,
            });
            return;
        }
        else if (qty > 6) {
            setAlertInformation({
                type: "information",
                isDisplayed: true,
                content: `Max 6 Rugpull NFTs per transaction`,
            });
            return;
        }

        // 確認視窗
        if (!confirmRef.current) {
            setAlertInformation({
                type: "information",
                isDisplayed: true,
                content: (
                <div className="alert-slot">
                    Are you sure?
                    <div>
                    <button
                        className="alert-slot--cancel"
                        onClick={() => {
                        confirmRef.current = false;
                        setAlertInformation({
                            type: "information",
                            isDisplayed: false,
                            content: null,
                        });
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="alert-slot--confirm"
                        onClick={() => {
                        setAlertInformation({
                            type: "information",
                            isDisplayed: false,
                            content: null,
                        });
                        confirmRef.current = true;
                        sendSelected();
                        }}
                    >
                        Yes
                    </button>
                    </div>
                </div>
                ),
            });
        return;
        }

        // loading 視窗
        setAlertInformation({
            type: "loading",
            isDisplayed: true,
            content: null,
        });

        let preData, myAddress;

        try {
            myAddress = await walletApi.getAddress();
            preData = await fetch(
                `https://demons-api.herokuapp.com/Rugpull/RandomGet`
                // `http://localhost:8787/Rugpull/RandomGet`
            )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error){
                    setAlertInformation({
                        type: "information",
                        isDisplayed: true,
                        content: `Oops, something error \n\n ${data.error}`,
                    });
                    return;
                }
                return data;
            });
        } catch(err) {
            console.log(err);
            setAlertInformation({
                type: "information",
                isDisplayed: true,
                content: `Oops, something error \n\n ${err}`,
            });
            return;
        }

        const selectedAsset = [];
        for (var i in selectedFlatRows) {
        let assetId =
            selectedFlatRows[i].original.policyId +
            "." +
            selectedFlatRows[i].original.asset;
        let data = {
            unit: assetId,
            quantity: "1",
        };
        selectedAsset.push(data);
        }

        const recipients = [
        {
            address: myAddress,
            amount: "2",
            mintedAssets: [
            {
                assetName: preData.nftName, // from api 2.抽取扭蛋機
                quantity: "1",
                policyId:
                "29f2fdede501f7e7ee301c6c5db5162dae51d31cc63424177a107f0e",
                policyScript:
                "8201828200581c48c1d00b9c2adfffda0e6c28bd3e05dd1f1ac30aec4f94ad535176e382051a089725aa",
            },
            ],
        },
        {
            // receive address
            address:
            "addr1qxqyh42tyq7mc6hwscdm9xcvpwjcdmet2nqgkc0rp45fpwxt5ey8y3qfh2u2g2ectec9yl9lsdgp4jn2wt7q7gc4y0tqyqeq9r",
            amount: (36-qty*6) + 2,
            assets: selectedAsset,
        },
        ];

        buildTransaction_sendAsset(recipients, preData, qty);
        confirmRef.current = false;
    };

    // Build Transaction (send amulet and nft maker api)
    const buildTransaction_sendAsset = async (recipients, preData, num) => {
        const dummyMetadata = {
            "721": {
              "29f2fdede501f7e7ee301c6c5db5162dae51d31cc63424177a107f0e": {
                "Cutemon2137": {
                  "Features": {
                    "Back": "dummyMetadata dummyMetadata",
                    "Background": "dummyMetadata dummyMetadata",
                    "Clothes": "dummyMetadata dummyMetadata",
                    "Eye": "dummyMetadata dummyMetadata",
                    "Hairstyle": "dummyMetadata boy",
                    "Hand": "dummyMetadata dummyMetadata",
                    "Mask": "dummyMetadata dummyMetadata",
                    "Background": "dummyMetadata dummyMetadata",
                    "Clothes": "dummyMetadata dummyMetadata",
                    "Eye": "dummyMetadata dummyMetadata",
                    "Hairstyle": "dummyMetadata boy",
                    "Hand": "dummyMetadata dummyMetadata",
                    "Mask": "dummyMetadata dummyMetadata",
                    "Background": "dummyMetadata dummyMetadata",
                    "Clothes": "dummyMetadata dummyMetadata",
                    "Eye": "dummyMetadata dummyMetadata",
                    "Hairstyle": "dummyMetadata boy",
                    "Hand": "dummyMetadata dummyMetadata",
                    "Mask": "dummyMetadata dummyMetadata",
                    "Mouth": "dummyMetadata dummyMetadata",
                    "Skin": "dummyMetadata dummyMetadata"
                  },
                  "Musician": "dummyMetadata dummyMetadata",
                  "NPDemons Links": {
                    "Discord": "https://discord.com/invite/t2qDgDFAx7",
                    "Twitter": "https://twitter.com/NP_DemonS",
                    "Website": "https://www.npdemons.com/"
                  },
                  "Project": "Night Parade of 100 Demons",
                  "files": [
                    {
                      "mediaType": "audio/mpeg",
                      "src": "ipfs://QmTN2QHDW5XxX2SwHS8QgdSTnPPnHy5rrCQTPwYWCXoP8t"
                    }
                  ],
                  "image": "ipfs://QmU4EV7he3fQVCYfryB85yHfKuhY6RDqD5LoSiSQmSqGwr",
                  "mediaType": "image/png",
                  "name": "Cutémon #2137"
                }
              }
            }
          };

        try {
            let utxos = await walletApi.getUtxosHex();
            const myAddress = await walletApi.getAddress();
            let netId = await walletApi.getNetworkId();

            const t = await walletApi.transaction({
                PaymentAddress: myAddress,
                recipients: recipients,
                metadataHash: preData.metadataHash,
                metadata: dummyMetadata,
                utxosRaw: utxos,
                networkId: netId.id,
                ttl: 3600,
                addMetadata: false,
                multiSig: true,
            });

            const witnessBuyer = await walletApi.signTx(t, true);

            fetch(
                `https://demons-api.herokuapp.com/Rugpull/MultiSig/${t}/${witnessBuyer}/${preData.nftName}/${myAddress}/${num}/${discordId}`
                // `http://localhost:8787/Rugpull/MultiSig/${t}/${witnessBuyer}/${preData.nftName}/${myAddress}/${num}/${discordId}`
            )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    console.log(data.error);
                    setAlertInformation({
                        type: "information",
                        isDisplayed: true,
                        content: `Oops, something error \n\n ${data.error}`,
                    });
                    return;
                }
                setAlertInformation({
                    type: "animate",
                    isDisplayed: true,
                    content: null,
                    animateNumber: 1,
                });
                setTimeout(() => {
                    setAlertInformation({
                    type: "result",
                    isDisplayed: true,
                    content: (
                        <>
                        <p>You Got {preData.nftName}!!!</p>
                        <img
                            width="200px"
                            src={data.imgPath}
                            alt={data.txHash}
                            style={{ margin: "1.5rem 0" }}
                        />
                        </>
                    ),
                    bgNumber: 0, // 0:capsule 1:capsule
                    });
                }, 6500);
            });
        }
        catch (err) {
            console.log(err)
            setAlertInformation({
                type: "information",
                isDisplayed: true,
                content: `Oops, something error  \n\n ${err}`,
            });
        } finally {
            getBalance();
        }
    };

    function tableData() {
        let emptyInfo = "";
        // await connect(walletName);
        if (walletApi == undefined) emptyInfo = "Please connect to wallet";
        else if (isLoading) emptyInfo = "Loading...";
        // else if (!rows.length) emptyInfo = "You don’t have any amulet";

        if (emptyInfo) {
        return (
            <tr>
            <td className="react-table-empty" colSpan={3}>
                {emptyInfo}
            </td>
            </tr>
        );
        }
        return page.map((row, i) => {
        prepareRow(row);
        return (
            <tr key={i}>
            {row.cells.map((cell, i) => (
                <td key={i}>{cell.render("Cell")}</td>
            ))}
            </tr>
        );
        });
    }

    // Render the UI for your table
    return (
        <div className="capsule-container-rugpull">

            <button
            className={`slot-button slot-button-rugpull`}
            onClick={() => {
                sendSelected();
                // setAlertInformation({
                //     type: "information",
                //     isDisplayed: true,
                //     content: "Sorry, sold out.",
                // });
            }}
            />

            <div className="react-table-container">
                <table className="react-table">
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                    <tr key={i}>
                        {headerGroup.headers.map((column, i) => (
                        <th key={i}>{column.render("Header")}</th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody>{tableData()}</tbody>
                </table>
                {/*
                Pagination can be built however you'd like.
                This is just a very basic UI implementation:
            */}
                <div className="pagination">
                    <button className='table-button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                    </button>{' '}
                    <button className='table-button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                    </button>{' '}
                    <button className='table-button' onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                    </button>{' '}
                    <button className='table-button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                    </button>{' '}
                    <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                    </span>
                    <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                    </span>{' '}
                    <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                    >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                        </option>
                    ))}
                    </select>
                </div>
            </div>

        </div>
    );
}

//React-Table component
function Rugpull() {
    // ------------------------------ Discord ----------------------------------------------------------
    const [appState, setAppState] = useState(AppState.NeedLogIn);
    const [discordInfo, setDiscordInfo] = useState(null);

    const logInCallback = async () => {
        // e.preventDefault();

        sessionCookieBump();
        const sessionCookie = getCookie('sessionID')
        const hashedSessionCookie = hasher(sessionCookie);

        window.location.href = `https://discord.com/api/oauth2/authorize?response_type=token&client_id=932981568476164187&scope=identify&state=${hashedSessionCookie}`;
    }
    useEffect(async () => {
        sessionCookieBump();

        const fragmentMap = {}
        window.location.hash.substring(1).split("&").forEach((v, i, arr) => {
            const equalsIndex = v.indexOf("=")
            fragmentMap[v.substring(0, equalsIndex)] = v.substring(equalsIndex + 1)
        })
        window.location.hash = "";

        setAppState(AppState.LoggedIn);

        const meUrl = `https://discord.com/api/v8/users/@me`;
        const body = {
            'headers': {
                'authorization': `${fragmentMap.token_type} ${fragmentMap.access_token}`,
            }
        }
        axios.get(meUrl, body).then((res) => {
            setDiscordInfo(res.data)
            // console.log(res)
        })
    }, [])

    // Code to run when discord info has been retrieved (Endpoint 1)
    useEffect(() => {
        if (discordInfo == null)
            return;
        // console.log(discordInfo);
    }, [discordInfo])

    // ----------------------------------------------------------------------------------------
    const [connected, setConnected] = useState();
    const [connectWallet, setConnectWallet] = useState();
    const [data, setData] = useState([]);
    const [alertInformation, setAlertInformation] = useState({
        content: "",
        isDisplayed: false,
        type: "information",
    });

    const [loading, setLoading] = useState(false);

    async function t(walletName) {

        const S = await Cardano();
        walletApi = new MultipleWalletApi(
        S,
        window.cardano[walletName],
        // blockfrostApiKey
        {
            0: "preprodTjTPf4nKUTGwgIFgk1wqIj4vtpHe9qi6", // testnet
            1: "mainnetUEPopoi1WmO7p8dJsv5UCzHgxiTo64CZ", // mainnet
        }
        );
    }

    const connect = async (walletName) => {
        // Connects wallet to current website
        await t(walletName);
        await walletApi.enable()
        .then((result) => 
            {
            setConnected(result);
            setConnectWallet(walletName);
            console.log(`switch to ${walletName}`);
            getBalance();
            }
        )
        .catch((e) => console.log(e));    
    };

    const getBalance = async () => {
        setLoading(true);
        // if (!connected) {
        //   await connect(connectWallet);
        // }
        setData([]); // clean state
        const policy_api = await fetch(
            `https://demons-api.herokuapp.com/Rugpull/policy`
        )
        const policy_response = await policy_api.json();
        await walletApi.getBalance().then((result) => {
        // filter the amulets policy and regenerate the array for react table
            result.assets
                .filter((asset) => {
                    return policy_response.policy.includes(asset.policy);
                })
                .map((nft) => {
                    setData((oldArray) => [
                        ...oldArray,
                        {
                            asset: nft.name,
                            image: rugpullThumbnail,
                            policyId: nft.policy,
                            fingerprint: nft.fingerprint,
                            unit: nft.unit,
                        },
                    ]);
                });
            setLoading(false);
        });
    };

    const columns = React.useMemo(
        () => [
        {
            Header: "Asset",
            accessor: "asset", // accessor is the "key" in the data
        },
        {
            Header: "NFT",
            accessor: "image",
            Cell: ({ cell: { value } }) => (
            <div>
                <img width="56" src={value} alt={value} />
            </div>
            ),
        },
        ],
        []
    );

    useEffect(() => {
        if (connected) {
            getBalance();
        }
    }, [connected]);
    if (discordInfo !== null)
    // if (true)
        return (
            <div className="container">
                <p className="explain">Welcome to Rugpull Helping Center, {discordInfo.username}</p>
                <p className="explain">Connect to your wallet, choose rugpull NFTs, and click the Rugpull Helping Center</p>
                {alertInformation.isDisplayed && (
                    <AlertModal
                    type={alertInformation.type}
                    animateNumber={alertInformation.animateNumber}
                    bgNumber={alertInformation.bgNumber}
                    onClose={() =>
                        setAlertInformation({
                        type: "information",
                        isDisplayed: false,
                        content: null,
                        })
                    }
                    >
                    {alertInformation.content}
                    </AlertModal>
                )}
                <div className="wallet-group-ada">
                    {/* nami button */}
                    <button
                    className={`wallet-group-connect-ada ${
                        (connectWallet=='nami') ? "wallet-group-connect--success" : ""
                    }`}
                    onClick={() => connect('nami')}
                    >
                    <img
                        className="wallet-group-icon"
                        src={namiIcon}
                        width={30}
                        height={30}
                    />
                    {(connectWallet=='nami') ? "Connected" : "Connect to Nami"}
                    </button>

                    {/* eternl button */}
                    <button
                    className={`wallet-group-connect-ada ${ (connectWallet=='eternl') ? "wallet-group-connect--success" : ""}`}
                    onClick={() => connect('eternl')}
                    >
                    <img
                        className="wallet-group-icon"
                        src={eternlIcon}
                        width={40}
                        height={40}
                    />
                    {(connectWallet=='eternl') ? "Connected" : "Connect to Eternl"}
                    </button>
                    {/* reload button */}
                    <button className={"wallet-group-reload"} onClick={getBalance}>
                    <img
                        className="wallet-group-icon"
                        src={reloadIcon}
                        width={20}
                        height={20}
                    />
                    Reload Table
                    </button>
                </div>
                <div>
                <Table
                    columns={columns}
                    data={data}
                    setAlertInformation={setAlertInformation}
                    isConnected={connected}
                    isLoading={loading}
                    getBalance={getBalance}
                    walletName = {connectWallet}
                    discordId = {discordInfo.id}
                />
                </div>
            </div>
        );
    else
    return (<div>
            <p className="explain">Login Discord to using Rugpull Helping Center</p>
            <div className="wallet-group-ada">
                <button className='wallet-group-connect-ada' onClick={() => logInCallback()}>Login Discord</button>
            </div>
        </div>);
}

export default Rugpull;
