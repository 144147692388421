import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

import PolicyID_popout from "../ui/PolicyID_popout";
import Backdrop from "../ui/Backdrop";
import Menu from "../ui/menu";

import logo from "../../img/logo.png";
import hambuger from "../../img/icons/hamburger.svg";

import "./MainNavigation_mobile.css";

function MainNavigation_mobile() {
  const [policyIsPopout, setPolicyIsPopout] = useState(false);
  const [menuIsPopout, setMenuIsPopout] = useState(false);

  function closePolicyHandler() {
    setPolicyIsPopout(false);
  }
  const menuClose = () => {
    setMenuIsPopout(false);
  };

  useEffect(() => {
    if (policyIsPopout || menuIsPopout)
      document.body.classList.add("stop-scroll");
    if (!policyIsPopout && !menuIsPopout)
      document.body.classList.remove("stop-scroll");

    return () => {
      document.body.classList.remove("stop-scroll");
    };
  }, [policyIsPopout, menuIsPopout]);

  return (
    <header className="header--mobile">
      <div className="header-hamburger" onClick={() => setMenuIsPopout(true)}>
        <img width={20} src={hambuger} />
      </div>

      <Link to="/">
        <img className="header-logo" src={logo} />
      </Link>
      <Menu type="mobile" />

      {menuIsPopout && (
        <div className="header-nav">
          <div className="header-nav-close">
            <div style={{ marginTop: "0.8rem" }} onClick={menuClose}>
              <Link to="/">
                <img className="header-logo" src={logo} />
              </Link>
            </div>
            <span onClick={menuClose}>x</span>
          </div>
          <div className="header-nav-item" onClick={menuClose}>
            <Link to="schedule">Schedule</Link>
          </div>
          <div className="header-nav-item" onClick={menuClose}>
            <Link to="roadmap">Roadmap</Link>
          </div>
          <div className="header-nav-item" onClick={menuClose}>
            <Link to="team">Team</Link>
          </div>
          {/* <div className="header-nav-item" onClick={menuClose}>
            <Link to="partner">Partner</Link>
          </div> */}
          {/* <div className="header-nav-item" onClick={menuClose}>
            <Link to="/gallery">Gallery</Link>
          </div> */}
          {/* <div className="header-nav-item" onClick={menuClose}>
            <Link to="/capsuleMachine">Capsule Machine</Link>
          </div> */}
          {/* <div className="header-nav-item" onClick={menuClose}>
            <Link to="/demonClub">Demon Club</Link>
          </div> */}
          <div className="header-nav-item" onClick={menuClose}>
            <Link to="/mint">Mint Amulet</Link>
          </div>
          <div className="header-nav-item" onClick={menuClose}>
            <Link to="/rugpull">Rugpull Helping Center</Link>
          </div>
          <div className="header-nav-item" onClick={menuClose}>
            <Link to="/stake">Staking</Link>
          </div>
        </div>
      )}

      {policyIsPopout && (
        <PolicyID_popout
          onCancel={closePolicyHandler}
          onConfirm={closePolicyHandler}
        />
      )}
      {policyIsPopout && <Backdrop onCancel={closePolicyHandler} />}
    </header>
  );
}

export default MainNavigation_mobile;
