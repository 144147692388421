import React, { useEffect, useRef, useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import AlertModal from "./alert";

import MultipleWalletApi, { Cardano } from "../../nami-js";

import amulets001 from "../../img/Amulets/amulets001.jpg";
import amulets002 from "../../img/Amulets/amulets002.jpg";
import amulets003 from "../../img/Amulets/amulets003.jpg";
import amulets004 from "../../img/Amulets/amulets004.jpg";
import amulets005 from "../../img/Amulets/amulets005.jpg";
import amulets006 from "../../img/Amulets/amulets006.jpg";
import amulets007 from "../../img/Amulets/amulets007.jpg";
import amulets008 from "../../img/Amulets/amulets008.jpg";
import amulets009 from "../../img/Amulets/amulets009.jpg";
import amulets010 from "../../img/Amulets/amulets010.jpg";
import amulets011 from "../../img/Amulets/amulets011.jpg";
import amulets012 from "../../img/Amulets/amulets012.jpg";
import amulets013 from "../../img/Amulets/amulets013.jpg";
import amulets014 from "../../img/Amulets/amulets014.jpg";
import amulets015 from "../../img/Amulets/amulets015.jpg";



import namiIcon from "../../img/icons/nami.svg";
import eternlIcon from "../../img/icons/eternl.png";

import reloadIcon from "../../img/icons/reload.png";

import "./ReactRable.css";

let walletApi;
// console.log(walletApi);
// Change assset name to image
function assetNametoImage(param) {
  let num = parseInt(param.split(/(\d+)/)[1]);
  if (num < 61) {
    return amulets001;
  } else if (num < 121) {
    return amulets002;
  } else if (num < 181) {
    return amulets003;
  } else if (num < 241) {
    return amulets004;
  } else if (num < 301) {
    return amulets005;
  } else if (num < 361) {
    return amulets006;
  } else if (num < 421) {
    return amulets007;
  } else if (num < 481) {
    return amulets008;
  } else if (num < 541) {
    return amulets009;
  } else if (num < 601) {
    return amulets010;
  } else if (num < 661) {
    return amulets011;
  } else if (num < 721) {
    return amulets012;
  } else if (num < 781) {
    return amulets013;
  } else if (num < 841) {
    return amulets014;
  } else if (num < 901) {
    return amulets015;
  }
  else {
    return "error";
  }
}
// Checkbox
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);
// Table function
function Table({
  columns,
  data,
  setAlertInformation,
  isLoading,
  getBalance,
  capsuleAmount,
  // walletName,
}) {
  const confirmRef = useRef(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows ->
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    prepareRow,
    selectedFlatRows,
    state: {  pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  // Send Selected Assets
  const sendSelected = async (selectNum) => {
    const qty = selectNum;

    // 選擇不夠
    if (Object.keys(selectedRowIds).length !== qty) {
      setAlertInformation({
        type: "information",
        isDisplayed: true,
        content: `This Capsule Machine requires ${qty} amulets \n but you select ${
          Object.keys(selectedRowIds).length
        } amulet(s)`,
      });
      return;
    }

    // 確認視窗
    if (!confirmRef.current) {
      setAlertInformation({
        type: "information",
        isDisplayed: true,
        content: (
          <div className="alert-slot">
            Are you sure?
            <div>
              <button
                className="alert-slot--cancel"
                onClick={() => {
                  confirmRef.current = false;
                  setAlertInformation({
                    type: "information",
                    isDisplayed: false,
                    content: null,
                  });
                }}
              >
                Cancel
              </button>
              <button
                className="alert-slot--confirm"
                onClick={() => {
                  setAlertInformation({
                    type: "information",
                    isDisplayed: false,
                    content: null,
                  });
                  confirmRef.current = true;
                  sendSelected(selectNum);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        ),
      });
      return;
    }

    // loading 視窗
    setAlertInformation({
      type: "loading",
      isDisplayed: true,
      content: null,
    });

    let preData, myAddress;

    try {
      myAddress = await walletApi.getAddress();
      preData = await fetch(
        `https://demons-api.herokuapp.com/CapsuleMachine/RandomGet/${qty}`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          return data;
        });
    } catch(err) {
      console.log(err);
      setAlertInformation({
        type: "information",
        isDisplayed: true,
        content: "Oops, something error",
      });
      return;
    }

    const selectedAsset = [];
    for (var i in selectedFlatRows) {
      let assetId =
        selectedFlatRows[i].original.policyId +
        "." +
        selectedFlatRows[i].original.asset;
      let data = {
        unit: assetId,
        quantity: "1",
      };
      selectedAsset.push(data);
    }

    const recipients = [
      {
        address: myAddress,
        amount: "0",
        mintedAssets: [
          {
            assetName: preData.nftName, // from api 2.抽取扭蛋機
            quantity: "1",
            policyId:
              "29f2fdede501f7e7ee301c6c5db5162dae51d31cc63424177a107f0e",
            policyScript:
              "8201828200581c48c1d00b9c2adfffda0e6c28bd3e05dd1f1ac30aec4f94ad535176e382051a089725aa",
          },
        ],
      },
      {
        // receive address
        address:
          "addr1q89tgeaadsmur54s8tza8q98aq59hwkf8ay6fx4wrtpeg6ewreqq59wsgj9dsqv9wnkrx5v7w7zey3nnyzf5ugvrdrmsxmxc2m",
        amount: 1,
        assets: selectedAsset,
      },
    ];

    buildTransaction_sendAsset(recipients, preData, qty);
    confirmRef.current = false;
  };

  // Build Transaction (send amulet and nft maker api)
  const buildTransaction_sendAsset = async (recipients, preData, num) => {
    const dummyMetadata = {
      721: {
        "29f2fdede501f7e7ee301c6c5db5162dae51d31cc63424177a107f0e": {
          Cutemondummy: {
            name: "Cutémon #dummy",
            image: "ipfs://dummydummydummydummydummydummydummydummy",
            mediaType: "image/pngdummy",
            Features: {
              Background: "dummy dummydummydummy",
              Back: "dummy inu Taildummydummy",
              Skin: "Green dummy dummydummy",
              Clothes: "dummy dummy dummydummy",
              Hairstyle: "dummy boy dummydummy",
              Eye: "One pundummychdummy",
              Mouth: "dummy dummydummy",
              Mask: "dummy dummdummyy",
              Hand: "dummy - dumdummymy Pablo and Frida",
            },
            files: [
              {
                mediaType: "audio/mpegdummy",
                src: "ipfs://dummydummydummydummydummydummyddummy",
              },
            ],
            Project: "Night Parade of 100 Demons dummy",
            Musician: "ALephaNt dummydummydummy",
            "NPDemons Links": {
              Website: "https://www.npdemons.com/",
              Twitter: "https://twitter.com/NP_DemonS",
              Discord: "https://discord.com/invite/t2qDgDFAx7",
            },
          },
        },
        version: "1.0dummydummydummy",
      },
    };

    try {
      let utxos = await walletApi.getUtxosHex();
      const myAddress = await walletApi.getAddress();
      let netId = await walletApi.getNetworkId();

      const t = await walletApi.transaction({
        PaymentAddress: myAddress,
        recipients: recipients,
        metadataHash: preData.metadataHash,
        metadata: dummyMetadata,
        utxosRaw: utxos,
        networkId: netId.id,
        ttl: 3600,
        addMetadata: false,
        multiSig: true,
      });

      const witnessBuyer = await walletApi.signTx(t, true);

      fetch(
        `https://demons-api.herokuapp.com/CapsuleMachine/MultiSig/${t}/${witnessBuyer}/${preData.nftName}/${myAddress}/${num}`
        // `http://localhost:8787/CapsuleMachine/MultiSig/${t}/${witnessBuyer}/${preData.nftName}/${myAddress}/${num}`

      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.message) {
            console.log(data.message);
            setAlertInformation({
              type: "information",
              isDisplayed: true,
              content: `Oops, something error \n\n ${data.message}`,
            });
            return;
          }

          setAlertInformation({
            type: "animate",
            isDisplayed: true,
            content: null,
            animateNumber: num,
          });
          setTimeout(() => {
            setAlertInformation({
              type: "result",
              isDisplayed: true,
              content: (
                <>
                  <p>You Got {preData.nftName}!!!</p>
                  <img
                    width="200px"
                    src={data.imgPath}
                    alt={data.txHash}
                    style={{ margin: "1.5rem 0" }}
                  />
                </>
              ),
              bgNumber: 0, // 0:capsule 1:capsule
            });
          }, 6500);
        });
    } catch (err) {
      console.log(err)
      setAlertInformation({
        type: "information",
        isDisplayed: true,
        content: "Oops, something error",
      });
    } finally {
      getBalance();
    }
  };

  function tableData() {
    let emptyInfo = "";
    // await connect(walletName);
    if (walletApi == undefined) emptyInfo = "Please connect to wallet";
    else if (isLoading) emptyInfo = "Loading...";
    // else if (!rows.length) emptyInfo = "You don’t have any amulet";

    if (emptyInfo) {
      return (
        <tr>
          <td className="react-table-empty" colSpan={3}>
            {emptyInfo}
          </td>
        </tr>
      );
    }
    return page.map((row, i) => {
      prepareRow(row);
      return (
        <tr key={i}>
          {row.cells.map((cell, i) => (
            <td key={i}>{cell.render("Cell")}</td>
          ))}
        </tr>
      );
    });
  }

  // Render the UI for your table
  return (
    <div className="capsule-container">
      <div>
        <button
          className={`slot-button slot-button-1 ${
            !capsuleAmount[0] && "slot-button-1-sold"
          }`}
          onClick={() => {
            if (capsuleAmount[0]) sendSelected(2);
            else
              setAlertInformation({
                type: "information",
                isDisplayed: true,
                content: "Sorry, sold out.",
              });
          }}
        />
        <p className="slot-amount">{capsuleAmount[0]} / 80</p>
      </div>
      <div>
        <button
          className={`slot-button slot-button-2 ${
            !capsuleAmount[1] && "slot-button-2-sold"
          }`}
          onClick={() => {
            if (capsuleAmount[1]) sendSelected(4);
            else
              setAlertInformation({
                type: "information",
                isDisplayed: true,
                content: "Sorry, sold out.",
              });
          }}
        />
        <p className="slot-amount">{capsuleAmount[1]} / 40</p>
      </div>
      <div>
        <button
          className={`slot-button slot-button-3 ${
            !capsuleAmount[2] && "slot-button-3-sold"
          }`}
          onClick={() => {
            if (capsuleAmount[2]) sendSelected(6);
            else
              setAlertInformation({
                type: "information",
                isDisplayed: true,
                content: "Sorry, sold out.",
              });
          }}
        />
        <p className="slot-amount">{capsuleAmount[2]} / 10</p>
      </div>
      <div className="react-table-container">
        <table className="react-table">
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th key={i}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>{tableData()}</tbody>
        </table>
        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div className="pagination">
        <button className='table-button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button className='table-button' onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button className='table-button' onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button className='table-button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      </div>
    </div>
  );
}

//React-Table component
function SwapTable() {
  const [connected, setConnected] = useState();
  const [connectWallet, setConnectWallet] = useState();
  const [data, setData] = useState([]);
  const [alertInformation, setAlertInformation] = useState({
    content: "",
    isDisplayed: false,
    type: "information",
  });
  const [loading, setLoading] = useState(false);
  const [capsuleAmount, setCapsuleAmount] = useState([0, 0, 0]);

  async function t(walletName) {

    const S = await Cardano();
    walletApi = new MultipleWalletApi(
      S,
      window.cardano[walletName],
      // blockfrostApiKey
      {
        0: "preprodTjTPf4nKUTGwgIFgk1wqIj4vtpHe9qi6", // testnet
        1: "mainnetUEPopoi1WmO7p8dJsv5UCzHgxiTo64CZ", // mainnet
      }
    );
  }

  const connect = async (walletName) => {
    // Connects wallet to current website
    await t(walletName);
    await walletApi.enable()
      .then((result) => 
        {
          setConnected(result);
          setConnectWallet(walletName);
          console.log(`switch to ${walletName}`);
          getBalance();
        }
      )
      .catch((e) => console.log(e));    
  };

  const getBalance = async () => {
    setLoading(true);
    // if (!connected) {
    //   await connect(connectWallet);
    // }
    setData([]); // clean state
    await walletApi.getBalance().then((result) => {
      // console.log(result);
      // filter the amulets policy and regenerate the array for react table
      result.assets
        .filter((asset) => {
          if (
            asset.policy !==
            "3124bddc14e0a7f0871e013acbbe3969170006e00f0603385b6465c7"
          ) {
            return false; // skip
          }
          return true;
        })
        .map((nft) => {
          setData((oldArray) => [
            ...oldArray,
            {
              asset: nft.name,
              image: assetNametoImage(nft.name),
              policyId: nft.policy,
              fingerprint: nft.fingerprint,
              unit: nft.unit,
            },
          ]);
        });
      setLoading(false);
    });
    setAllCapsuleAmount();
  };

  const setAllCapsuleAmount = async () => {
    const amount = await Promise.all([
      getCapsuleAmount(2),
      getCapsuleAmount(4),
      getCapsuleAmount(6),
    ]);
    setCapsuleAmount(amount);
  };

  const getCapsuleAmount = async (num) => {
    return await fetch(
      `https://demons-api.herokuapp.com/CapsuleMachine/Remaining/${num}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.amount;
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Asset",
        accessor: "asset", // accessor is the "key" in the data
      },
      {
        Header: "NFT",
        accessor: "image",
        Cell: ({ cell: { value } }) => (
          <div>
            <img width="56" src={value} alt={value} />
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (connected) {
      getBalance();
    }
  }, [connected]);

  useEffect(() => {
    setAllCapsuleAmount();
  }, []);

  return (
    <div className="container">
      <p className="explain">Connect to your wallet, choose your amulets, and click the capsule machine to play.</p>
      {alertInformation.isDisplayed && (
        <AlertModal
          type={alertInformation.type}
          animateNumber={alertInformation.animateNumber}
          bgNumber={alertInformation.bgNumber}
          onClose={() =>
            setAlertInformation({
              type: "information",
              isDisplayed: false,
              content: null,
            })
          }
        >
          {alertInformation.content}
        </AlertModal>
      )}
      <div className="wallet-group-ada">
        {/* nami button */}
        <button
          className={`wallet-group-connect-ada ${
            (connectWallet=='nami') ? "wallet-group-connect--success" : ""
          }`}
          onClick={() => connect('nami')}
        >
          <img
            className="wallet-group-icon"
            src={namiIcon}
            width={30}
            height={30}
          />
          {(connectWallet=='nami') ? "Connected" : "Connect to Nami"}
        </button>

        {/* eternl button */}
        <button
          className={`wallet-group-connect-ada ${ (connectWallet=='eternl') ? "wallet-group-connect--success" : ""}`}
          onClick={() => connect('eternl')}
        >
          <img
            className="wallet-group-icon"
            src={eternlIcon}
            width={40}
            height={40}
          />
          {(connectWallet=='eternl') ? "Connected" : "Connect to Eternl"}
        </button>
        {/* reload button */}
        <button className={"wallet-group-reload"} onClick={getBalance}>
          <img
            className="wallet-group-icon"
            src={reloadIcon}
            width={20}
            height={20}
          />
          Reload Table
        </button>
      </div>
      <Table
        columns={columns}
        data={data}
        setAlertInformation={setAlertInformation}
        isConnected={connected}
        isLoading={loading}
        getBalance={getBalance}
        capsuleAmount={capsuleAmount}
        walletName = {connectWallet}
      />
    </div>
  );
}

export default SwapTable;
