import React from 'react';

import classes from '../../style.module.css'


function PolicyID() {
    return <div >
        <div id={classes.policyid} className={ classes.policyid + ' ' + classes.center}>PolicyID of Night parade of 100 demons:
            <text id={classes.demonsid} className={classes.policyid}>
                bec4f260ad9b8c4c2ee1d65dec4fff83b4210a008ffa4b61e4b91543</text ><br/> PolicyID of Auction amulets:
            <text id={classes.amuletsid} className={classes.policyid}> 3124bddc14e0a7f0871e013acbbe3969170006e00f0603385b6465c7</text><br/>PolicyID of Cutémon: coming soon
        </div>
    </div>;
}
  
export default PolicyID;