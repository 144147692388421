import React from 'react';

import classes from '../../style.module.css'
import amulets001 from '../../img/Amulets/amulets001.jpg'
import amulets002 from '../../img/Amulets/amulets002.jpg'
import amulets003 from '../../img/Amulets/amulets003.jpg'

function Collection() {
    return <div >
        <article id={classes.collection}>
            <h1>
                The 100 Demons collection
            </h1>
            is minted in an Auction system. Currently, we hold auctions in the Discord. If you want to take part in auctions, you need to mint an AuctionAmulet.
            <hr/>
            <hr/>
            <div className={classes.center}>
                <img className={classes.pics} src={amulets001}/>
                <img className={classes.pics} src={amulets002}/>
                <img className={classes.pics} src={amulets003}/>
            </div>
        </article>
    </div>;
}
  
export default Collection;