import React from "react";

import classes from "../style.module.css";
import classes_Capsule from "./CapsuleMachine.module.css";
import cutemon from "../img/capsule/cutemon.gif";
import ReactTable from "../components/ui/ReactTable";
import useWindowSize from "../hook/useWindowSize";


function NoCapsulePage() {
    return <div className={classes_Capsule.main}>
        
        <img src={cutemon} className={classes_Capsule.capsulesoon}/>
        <div className={classes.center + ' ' + classes_Capsule.letsplay}>
            <br/><br/><br/><br/><br/>
            <h3>Capsule machines Currently not avaliable</h3>
            <div className={classes_Capsule.colored}>
                {/* <h3>“What are the differences between playing capsule machines and minting directly?”</h3>
                1. Get cutémons earlier. <br/>
                2. Get rarer cutémons easier. <br/> 
                3. Get cutémons cheaper (2 amulets cost 30 ADA. ) <br/> Minting a cutemon costs 45 ADA. */}
            </div>
        </div>
    </div>;
    
}

function CapsuleMachinePage() {
  const size = useWindowSize();
  return (
    <div className={classes_Capsule.main}>
      {size.width > 800 ? (
        // <ReactTable />
        NoCapsulePage()
      ) : (
        <p>Not available for phone, please use desktop</p>
      )}
    </div>
  );
}

export default CapsuleMachinePage;
/*
<div className={classes_Capsule.popout + ' ' + classes.center} id={classes.idpopout}>
        <hr/>
        <h2> PolicyID</h2>

        <hr/> PolicyID of Night Parade of 100 Demons:<br/> bec4f260ad9b8c4c2ee1d65dec4fff83b4210a008ffa4b61e4b91543

        <hr/> PolicyID of Auction Amulets:<br/> 3124bddc14e0a7f0871e013acbbe3969170006e00f0603385b6465c7

        <hr/> PolicyID of Cutémon:<br/> Coming Soon...
        <hr/>
        <close id={classes_Capsule.idpopoutclose}>X</close>
    </div>
*/
