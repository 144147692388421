import React from "react";

import classes from "../../style.module.css";
import schedule from "../../img/schedule/schedule.png";
import "./schedule.css";

const Calender = ({ children }) => {
  return (
    <div className="calender">
      <img width={"50vw"} src={schedule} />
      {children}
    </div>
  );
};

function Schedule() {
  return (
    <div className="schedule">
      <div className="schedule-article">
        <h1>Schedule</h1>
        Auction Time: Every Saturday 2pm UTC in our Discord
        <br />
        Reserver Price: 6 ADA
        <br />
        Increment: 1 ADA
        <br /> Auction Period: 24 hour
      </div>
      <div className="schedule-calender">
        <Calender>
          {/* <div className="schedule-day schedule-day--text schedule-day--sun schedule-day--row6">
            Capsule Machine
          </div> */}
        </Calender>
      </div>
    </div>
  );
}

export default Schedule;
