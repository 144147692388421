import React from "react";

import classes from "../../style.module.css";

import Will from "../../img/capsule/intro/Will.jpg";
import Eric from "../../img/capsule/intro/Eric.jpg";
import Jane from "../../img/capsule/intro/Jane.jpg";
import Eserling from "../../img/capsule/intro/Eserling.png";

import "./team.css";

const TeamMemberCard = ({ src, title, subtitle, description }) => {
  return (
    <div className="team-member-card">
      <div className="team-member-card--avatar">
        <img width="100%" src={src} />
      </div>
      <div>
        <b className="team-member-card--title">{title}</b>
        <p className="team-member-card--subtitle">{subtitle}</p>
        <p className="team-member-card--description">{description}</p>
      </div>
    </div>
  );
};

function Team() {
  return (
    <div className="team">
      <div className="team-title">
        <h1>Team</h1>
        <h3>A Taiwanese cnft project. We love Japanese traditional culture.</h3>
      </div>
      <div className="team-member">
        <TeamMemberCard
          src={Will}
          title="Will | 威爾黃"
          subtitle="Community manager"
          description={`Born in Taichung Hiking \n running, derpling`}
        />
        <TeamMemberCard
          src={Eric}
          title="Eric | 艾瑞克蔡"
          subtitle="Lead developer"
          description={`American Taiwanese \n fast food lover`}
        />
        <TeamMemberCard
          src={Jane}
          title="Jane | 簡張"
          subtitle="Lead artist"
          description={`I hope I can sleep whenever I want.`}
        />
        <TeamMemberCard
          src={Eserling}
          title="Eserling | 艾瑟琳謝"
          subtitle="Deputy lead artist"
          description={`Fried shrimp, volleyball \n instagram @ebifura_art`}
        />
      </div>
    </div>
  );
  /*
    return <div>
        <article id={classes.team} className={classes.pc}>
            <h1>Teams</h1>
            <div className={classes.center}>
                <h3>A Taiwanese cnft project. We love Japanese traditional culture.</h3>
                <hr/>
                <hr/>
                <hr/>

            </div>
            <div >
                <div ><img src={beef} /></div>
                <div >
                    Will | 威爾黃
                    <br/><br/> Community manager
                    <br/><br/> Born in Taicnhung Hiking, running, derpling
                </div>
            </div>
            <div >

                <div ><img src={chicken} /></div>
                <div >
                    Eric | 艾瑞克蔡
                    <br/><br/> Lead developer
                    <br/><br/> American Taiwanese fast food lover
                </div>
            </div>
            <div style={style} ></div>

            <div >
                <div ><img src={fish} /></div>
                <div >
                    Jane | 簡張
                    <br/><br/> Lead artist
                    <br/><br/>I hope I can sleep whenever I want.
                </div>
            </div>
            <div >
                <div ><img src={tea} /></div>
                <div >
                    Eserling | 艾瑟琳謝
                    <br/><br/> Deputy lead artist
                    <br/><br/>Fried shrimp frying shrimps
                </div>
            </div>
            <div style={style2} ></div>
            </article>
    </div>;
    */
}

export default Team;
