import React, { useState } from "react";
import twitter from "../../img/icons/twitter.svg";
import discord from "../../img/icons/discord.svg";
import policyID from "../../img/icons/id.svg";
import PolicyID_popout from "./PolicyID_popout";
import Backdrop from "./Backdrop";

import "./menu.css";

const Menu = ({ type }) => {
  const [policyIsPopout, setPolicyIsPopout] = useState(false);

  function policyHandler() {
    setPolicyIsPopout(true);
  }

  function closePolicyHandler() {
    setPolicyIsPopout(false);
  }
  return (
    <>
      <menu className={`menu ${type === "mobile" && "menu--mobile"}`}>
        <a
          className="menu-icon menu-icon--twitter"
          href="https://twitter.com/NP_DemonS"
          target="blank"
        />
        <a
          className="menu-icon menu-icon--discord"
          href="https://discord.gg/t2qDgDFAx7"
          target="blank"
        />
        <div
          className="menu-icon menu-icon--policyID"
          onClick={policyHandler}
        />
      </menu>

      {policyIsPopout && (
        <PolicyID_popout
          onCancel={closePolicyHandler}
          onConfirm={closePolicyHandler}
        />
      )}
      {policyIsPopout && <Backdrop onCancel={closePolicyHandler} />}
    </>
  );
};

export default Menu;
